import React, { Component } from 'react';
import wellnessandTeliMedicineServiceStyle from 'ps-assets/jss/wellnessandTeliMedicineServiceStyle.jsx';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem.jsx';
import Layout from 'ps-components/Layout/Layout.jsx';
import ReactSVG from 'react-svg';
import Hidden from '@material-ui/core/Hidden';
import { withPrefix } from 'gatsby';
import { graphql } from 'gatsby';
import apple from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Apple_store_d.png';
import app from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Google_store_d.png';
import Tele_health_consultation from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Tele_health_consultations_b.jpg';
import Covid_19_Helpline from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Covid_19_Helpline_b.jpg';
import star_eye_care from 'assets/img/Wellness_landing_page_assests/Images_and_banner/star_eye_care_b.jpg';
import star_mental_care from 'assets/img/Wellness_landing_page_assests/Images_and_banner/star_mental_care_b.jpg';
import chronic_Disease_support from 'assets/img/Wellness_landing_page_assests/Images_and_banner/chronic_Disease_support_b.jpg';
import Hospital_Network from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Hospital_Network_b.jpg';
import ePharmacy from 'assets/img/Wellness_landing_page_assests/Images_and_banner/ePharmacy_b.jpg';
import Diagnostic_Centers from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Diagnostic_Center_b.jpg';
import Healthy_Ageing_for_Elders from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Healthy_Ageing_for_Elders_b.jpg';
import Health_Risk_Assessment from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Health_Risk_Assessment_b.jpg';
// import Electronic_Health_Records from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Electronic_Health_Records_b.jpg';
import Healthy_LifeStyle from 'assets/img/Wellness_landing_page_assests/Images_and_banner/Healthy_LifeStyle_b.jpg';
import GridContainer from 'components/Grid/GridContainer';
import NavPills from 'components/NavPills/NavPills';
import { browserLocalStorage, browserWindow } from 'util/localStorageHelper';
import { Helmet } from "react-helmet";
const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

let url = process.env.BASE_URL;
const navPillArr = [
  {
    icon: `/covid_19_helpline_blue.svg`,
    value: `COVID-19 HELPLINE`,
  },
  {
    icon: `/tele_health_consultation_blue.svg`,
    value: `TELE HEALTH CONSULTATION`,
  },
  {
    icon: `/epharmacy_blue_i.svg`,
    value: `EPHARMACY`,
  },
  {
    icon: `/diagnostic_centers_blue.svg`,
    value: `DIAGNOSTIC CENTERS`,
  },
  {
    icon: `/health_risk_assessment_blue.svg`,
    value: `HEALTH RISK ASSESSMENT`,
  },
  // {
  //   icon: `/electronics_health_records_blue.svg`,
  //   value: `ELECTRONICS HEALTH RECORDS`,
  // },
  {
    icon: `/hospital_network_blue.svg`,
    value: `HOSPITAL NETWORK`,
  },
];

class wellnessandTeliMedicineService extends Component {
  
  state = { loggedIn: false };

  getRedirectUrl() {
    if(this.state.loggedIn){
      let user = browserLocalStorage.getItem(`user`);
      user = JSON.parse(user);
      return `https://star-health-web.getvisitapp.com
        /star-health?token=${user.token}&userId=${user.userId}`;
    }
    return `${url}customerportal/login`;
  };

  updateLocalStorage = () => {
    try{
      if(this.state.loggedIn) browserLocalStorage.setItem('sessionalive', true)
      else browserLocalStorage.setItem(
        "websiteRedirectPostLogin",
        `https://star-health-web.getvisitapp.com`
      );
    }
    catch(exception){
      console.log(exception)
    }
  }

  NavigatePage = (action) => {
    switch (action) {
      case 0:
        browserWindow.location.href = '#covid-19';
        break;
      case 1:
        browserWindow.location.href = '#telehealth';
        break;
      case 2:
        browserWindow.location.href = '#epharmacybuy';
        break;
      case 3:
        browserWindow.location.href = '#diagnosticcenter';
        break;
      case 4:
        browserWindow.location.href = '#healthriskassessment';
        break;
      // case 5:
      //   browserWindow.location.href = `#electronichealthrecords`;
      //   break;
      case 5:
        browserWindow.location.href = '#networkhospitals';
        break;
      case 6:
        browserWindow.location.href = 'https://wellness.starhealth.in/healthcalculators';
        break;
    }
  };

  onLinkClick = () => {
    const isProd = process.env.BASE_URL == `https://www.starhealth.in/`;
    let wellnessUrl = `https://star-health-web.getvisitapp.com`;
    if (!isProd)
     wellnessUrl += `/star-health`;
    if (this.state.isLoggedIn) {
      wellnessUrl += `?token=${this.user.ssoToken}&userId=${this.user.userId}`;
      browserWindow.open(wellnessUrl, `_blank`);
    } else {
      browserLocalStorage.setItem(`websiteRedirectPostLogin`, wellnessUrl);
      browserWindow.open(
        `${process.env.BASE_URL}customerportal?utm_source=website&utm_medium=pagelink&utm_campaign=v1`,
        `_self`
      );
    }
  };

  userLoggedIn = (user) => {
    this.user = {ssoToken:user.ssoToken,userId : user.userId};
    this.setState({
      isLoggedIn: true
    });
  }

  updateLoggedIn(loggedIn){
    this.setState({
      loggedIn
    });
  }


  componentDidMount() {
    browserWindow.addEventListener(`storage`, (e) => {
      if (e.key === `user`) {
        let user = browserLocalStorage.getItem(`user`);
        let authToken = ``;
        if (user) {
          user = JSON.parse(user);
          authToken = user.token;
          if (authToken) this.updateLoggedIn(true);
          else this.updateLoggedIn(false);
        }else this.updateLoggedIn(false);
      }
    });
    let user = browserLocalStorage.getItem(`user`);
    user = JSON.parse(user);
    if (user && user.token) {
      this.userLoggedIn(user);
    }
  }

  render() {
    const { classes, data } = this.props;
    
    let user = browserLocalStorage.getItem(`user`);
    let authToken = ``;
    if (user) {
      user = JSON.parse(user);
      authToken = user.token;
    }

    var image =
      data.allStrapiImages.edges[0].node.content.childImageSharp.fluid;

    var mobileno = (
      <span style={{ fontSize: '20px', color: '#35488a', fontWeight: '550' }}>
        7676-905-905
      </span>
    );

    const searchString = browserWindow.location.search;
    const req = searchString && searchString.split(`?`)[1];
    const paramJson = {};
    req &&
      req.split(`&`).map((e) => {
        const p = e.split(`=`);
        paramJson[p[0]] = p[1];
      });
    let { token } = paramJson;
    authToken = token;
    if (authToken) {
      browserLocalStorage.setItem(`user`, JSON.stringify(paramJson));
      let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
      if (logOutToken) {
        authToken = ``;
      }
    } else {
      let user = browserLocalStorage.getItem(`user`);
      if (user) {
        user = JSON.parse(user);
        authToken = user.token;
        if (authToken) {
          let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
          if (logOutToken) {
            authToken = ``;
          }
        }
      }
    }

    var appdownload = (
      <span>
        <div style={{ paddingBottom: '3.2%', fontSize: '19px' }}>
          Download <b>Talk to Star</b> App{' '}
        </div>
        <a
          href='https://apps.apple.com/us/app/talk-to-star/id1501668829?ls=1'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={apple} style={{ width: '100px', height: '30px' }} />{' '}
        </a>
        <a
          href='https://play.google.com/store/apps/details?id=com.starhealthinsurance.talktostar'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={app}
            style={{ marginLeft: '15px', width: '100px', height: '30px' }}
          />
        </a>
      </span>
    );
    return (
      <Layout image1={image} image2={image} small={true}>
        <Helmet key="helmetTags">
    <title>Wellness and TeleMedicine | StarHealth.in</title>
  </Helmet>
  {process.env.ENABLE_HELMET == `true` && (
    <Helmet key="helmetTags">
      <link
        rel="canonical"
        href={url + `wellness`}
      />
      <meta name="title" content=" Wellness and TeleMedicine | StarHealth.in" />
      <meta name="twitter:title" content=" Wellness and TeleMedicine | StarHealth.in" />
      <meta
        name="description"
        content="Star Health Wellness and TeleMedicine"
      />
      <meta
        name="keywords"
        content="Star Health And Allied Insurance Company Limited, Star Health Care, Star Health Family Insurance, Star Health Insurance, Star Health Insurance Company, Star Health Insurance India, Star Health Insurance Plans, Star Health Insurance Policies, Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy"
      />
      <meta property="og:title" content=" Wellness and TeleMedicine | StarHealth.in" />
      <meta property="og:description" content="Star Health Wellness and TeleMedicine" />
      <meta property="og:url" content={url + `wellness`} />
      <meta name=" Wellness and TeleMedicine | StarHealth.in" />
      <meta name="twitter:description" content="Star Health Wellness and TeleMedicine" />
      <meta property="twitter:url" content={url + `wellness`} />
    </Helmet>
  )}
        <div className={classes.heeaderbanner}>
          <div className={classes.wellnessheader}>Wellness & TeleMedicine</div>
          <div className={classes.wellnesssummary}>
            From{' '}
            <span style={{ fontSize: '1.625rem' }}>Illness to Wellness</span>
          </div>
        </div>
        <div style={{ marginTop: '4.5%' }}>
          <NavPills
            color='primary'
            alignCenter
            variant
            tabs={navPillArr.map((pill) => ({
              tabImage: pill.icon,
              tabButton: pill.value,
            }))}
            onTabSwitch={this.NavigatePage}
          />
        </div>

        <GridContainer
          className={classes.container1}
          style={{ marginTop: '2.5%', paddingBottom: '7%' }}
        >
          <GridContainer
            id='covid-19'
            style={{ height: '100%', paddingBottom: '5%' }}
          >
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.content1title}
            >
              <div className={classes.gitiditemtitlefont}>
                Covid-19 Helpline
              </div>{' '}
              <hr className={classes.hrline2style} />
              <div className={classes.paddingTop}>
                <Hidden mdUp>
                  <GridItem
                    md={6}
                    lg={6}
                    xs={12}
                    sm={12}
                    className={classes.responsiveImg}
                  >
                    <img src={Covid_19_Helpline} className={classes.bannerimgsize} />
                  </GridItem>
                </Hidden>
                <GridContainer className={classes.helplineContainer}>
                  <GridItem md={6} lg={6} sm={6} xs={12}>
                    <div style={{ fontSize: '19px' }}>CALL</div>
                    <div>{mobileno}</div>
                    <div className={classes.callTime}>
                      8 AM to 1O PM on all days
                    </div>
                  </GridItem>
                  <GridItem
                    md={6}
                    lg={6}
                    xs={12}
                    sm={6}
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      marginLeft: '-3%',
                    }}
                  >
                    <div style={{ marginTop: '0%' }}>{appdownload}</div>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={classes.paddingTop}>
                <a
                  href='https://www.starhealth.in/covid-19'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <b>Take your covid risk assessment here</b>
                </a>
              </div>
              <div className={classes.paddingTop}>
                <b>Offering COVID advisory & home quarantine support</b>
              </div>
              <div className={classes.paddingTop}>
                Consultation offered with the health parameters, comorbidities
                of the specific individual in mind
              </div>
              <div className={classes.paddingTop}>
                Identify and manage COVID positve individuals for Home
                quarantine
              </div>
              <div className={classes.paddingTop}>
                Guidance on Sensitization, Sanitization, Sterilization
                protocols, Testing and Medication
              </div>
              <div className={classes.paddingTop}>
                Counselling for Chronic Disease care aggravated by COVID
              </div>
            </GridItem>
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingLeft: '0px', textAlign: `center` }}
              >
                <img src={Covid_19_Helpline} className={classes.bannerimgsize} />
              </GridItem>
            </Hidden>
          </GridContainer>
          <GridContainer md={12} xs={12} sm={12} className={classes.covidcontainerstyle}>
            <GridItem
              md={12}
              lg={12}
              xs={12}
              sm={12}
              className={classes.covidheaderstyle}
            >
              Pandemic Support
              <hr style={{ width: '8%', border: '1px solid green' }} />
            </GridItem>
            <GridContainer style={{ paddingRight: '4%' }}>
              <GridItem
                md={4}
                lg={4}
                xs={12}
                sm={12}
                className={classes.sections}
              >
                <div className={classes.eyecarediv}>
                  <GridItem
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                    style={{ padding: '0px' }}
                  >
                    <img
                      src={star_eye_care}
                      className={classes.eyecareimgstyle}
                    />
                  </GridItem>
                  <ReactSVG
                    src={withPrefix('/Star_Eye_Care_white.svg')}
                    className={classes.iconsposition}
                  />

                  <GridItem
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                    style={{ padding: '5%', paddingTop: '10%' }}
                  >
                    <div className={classes.container3divbody}>
                      <div className={classes.container3headerdiv}>
                        Eye Care
                      </div>{' '}
                      <hr className={classes.hrline3style} />
                      <div style={{ lineHeight: '20px' }}>
                        <div style={{ paddingTop: '3%', fontSize: '17px' }}>
                          Expert panel of <b>Ophthalmologists, </b>to help
                          patrons cope with prolonged screen time and associated
                          symptoms like migraine, eye sore, floaters, etc
                        </div>
                      </div>
                    </div>
                  </GridItem>
                </div>
              </GridItem>
              <GridItem md={4} lg={4} xs={12} sm={12}
                className={classes.sections}
              >
                <div className={classes.mentalcarediv}>
                  <GridItem
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                    style={{ padding: '0px' }}
                  >
                    <img
                      src={star_mental_care}
                      style={{ width: '100%', padding: '0px' }}
                    />
                  </GridItem>

                  <ReactSVG
                    src={withPrefix('/Star_Mind_Health_white.svg')}
                    className={classes.iconsposition}
                  />
                  <GridItem
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                    style={{ padding: '5%', paddingTop: '10%' }}
                  >
                    <div className={classes.container3divbody}>
                      <div className={classes.container3headerdiv}>
                        Mind Health
                      </div>{' '}
                      <hr className={classes.hrline3style} />
                      <div
                        style={{ lineHeight: '20px', paddingBottom: '80px' }}
                      >
                        <div style={{ paddingTop: '3%', fontSize: '17px' }}>
                          Team of trained <b>Psychiatrists</b> and{' '}
                          <b>Psychologists</b> for individuals experiencing
                          Stress and Anxiety related issues
                        </div>
                      </div>
                    </div>
                  </GridItem>
                </div>
              </GridItem>
              <GridItem
                md={4}
                lg={4}
                xs={12}
                sm={12}
                className={classes.sections}
              >
                <div className={classes.mentalcarediv}>
                  <GridItem
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                    style={{ padding: '0px' }}
                  >
                    <img
                      src={chronic_Disease_support}
                      style={{ width: '100%', padding: '0px' }}
                    />
                  </GridItem>
                  <ReactSVG
                    src={withPrefix('/Chronic_Disease_Support_white.svg')}
                    className={classes.iconsposition}
                  />

                  <GridItem
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                    style={{ padding: '5%', paddingTop: '10%' }}
                  >
                    <div className={classes.container3divbody}>
                      <div
                        className={classes.container3headerdiv}
                        style={{ width: '110%' }}
                      >
                        Chronic Disease <div> Support</div>
                      </div>{' '}
                      <hr className={classes.hrline3style} />
                      <div style={{ lineHeight: '20px' }}>
                        <div style={{ paddingTop: '3%', fontSize: '17px' }}>
                          Hesitancy in visiting hospitals during the pandemic is
                          leading to complications in individuals suffering from
                          chronic diseases such as{' '}
                          <b>Asthma, TB, Diabetes, Hypertension and Thyroid.</b>
                        </div>
                      </div>
                    </div>
                  </GridItem>
                </div>
              </GridItem>
            </GridContainer>
          </GridContainer>

          <GridContainer
            id='telehealth'
            style={{ height: '100%', paddingTop: '5%' }}
            md={12}
            sm={12}
            xs={12}
          >
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingRight: '0px', textAlign: `center` }}
              >
                <img
                  src={Tele_health_consultation}
                  className={classes.bannerimgsize}
                />
              </GridItem>
            </Hidden>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.content1title}
            >
              <div className={classes.gitiditemtitlefont}>
                Tele Health Consultation
              </div>{' '}
              <hr className={classes.hrline2style} />
              <div className={classes.paddingTop}>
                <Hidden mdUp>
                  <GridItem
                    md={6}
                    lg={6}
                    xs={12}
                    sm={12}
                    className={classes.responsiveImg}
                  >
                    <img
                      src={Tele_health_consultation}
                      className={classes.bannerimgsize}
                    />
                  </GridItem>
                </Hidden>
                <GridContainer className={classes.helplineContainer}>
                  <GridItem md={6} lg={6} xs={12} sm={6}>
                    <div style={{ fontSize: '19px' }}>CALL</div>
                    <div>{mobileno}</div>
                    <div className={classes.callTime}>
                      8 AM to 1O PM on all days
                    </div>
                  </GridItem>
                  <GridItem
                    md={6}
                    lg={6}
                    xs={12}
                    sm={6}
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      marginLeft: '-3%',
                    }}
                  >
                    <div style={{ marginTop: '0%' }}>{appdownload}</div>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={classes.paddingTop}>
                <b>Phone, chat or video consultations with doctors</b>
              </div>
              <div className={classes.paddingTop}>
                Open to Star Health customers and the general public{' '}
                <b>at No Cost.</b>
              </div>
              <div className={classes.paddingTop}>
                <b> Consult with specialists</b> across General Medicine, Family
                medicine, Cardiology, Pediatrics, Diabetology, Oncology,
                Ophthalmology, Orthopedics, Infectious diseases, Dentistry,
                Ob/Gyn, Neurology, Psychiatry, Dermatology & Clinical
                Microbiology.
              </div>
              <div className={classes.paddingTop}>
                Primary care, Second opinions and Decision support
              </div>
              <div className={classes.paddingTop}>
                Major Indian Languages supported
              </div>
              <div className={classes.paddingTop}>
                ePrescriptions, Investigations & diagnosis shared at the end of
                consultation
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer md={12} sm={12} xs={12} id='epharmacybuy' className={classes.container2style}>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.content1title}
              style={{ paddingTop: '13%' }}
            >
              <div className={classes.gitiditemtitlefont}>ePharmacy</div>{' '}
              <hr className={classes.hrline2style} />
              <Hidden mdUp>
                <GridItem
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  className={classes.responsiveImg}
                >
                  <img src={ePharmacy} className={classes.bannerimgsize} />
                </GridItem>
              </Hidden>
              <div className={classes.contentfontsize}>
                {/* <div className={classes.paddingTop}>
                  <a
                    href='https://sop.starhealth.in/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <b>Order now to avail discount in ePharmacy</b>
                  </a>
                </div> */}

                <div className={classes.divwidth}>
                  <b>Home delivery and Store pick</b> up options available at{' '}
                  <b>2,780 cities</b> around <br />
                  the country
                </div>
                <div className={classes.paddingTop}>
                  Upload prescription and delivery address.
                </div>
                <div className={classes.paddingTop}>
                  Call centre to get in touch
                </div>
                <div className={classes.paddingTop}>
                  Cash on Delivery payment options available
                </div>
                <div className={classes.paddingTop}>
                  Offered to Star Health Customers @ discount
                </div>
              </div>
            </GridItem>
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingLeft: '0px', textAlign: `center` }}
              >
                <img src={ePharmacy} className={classes.bannerimgsize} />
              </GridItem>
            </Hidden>
          </GridContainer>
          <GridContainer
            id='diagnosticcenter'
            className={classes.container2style}
          >
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingRight: '0px', textAlign: `center` }}
              >
                <img src={Diagnostic_Centers} className={classes.bannerimgsize} />
              </GridItem>
            </Hidden>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.diagnosticgriditem}
            >
              <div className={classes.gitiditemtitlefont}>
                Diagnostic Center
              </div>{' '}
              <hr className={classes.hrline2style} />
              <Hidden mdUp>
                <GridItem
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  className={classes.responsiveImg}
                >
                  <img src={Diagnostic_Centers} className={classes.bannerimgsize} />
                </GridItem>
              </Hidden>
              <div
                className={classes.contentfontsize}
                style={{ paddingBottom: '12%' }}
              >
                {/* <div className={classes.paddingTop}>
                  <a
                    href='https://sop.starhealth.in/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <b>Find your nearby center</b>
                  </a>
                </div> */}

                <div className={classes.divwidth}>
                  Access to <b>1,635 diagnostic centers</b> across the country
                </div>
                <div className={classes.paddingTop}>
                  Home pickup available for lab samples
                </div>
                <div className={classes.paddingTop}>
                  Preventive health checkup at your doorstep
                </div>
                <div className={classes.paddingTop}>
                  Offered to Star Health Customers @ discount
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer md={12} sm={12} xs={12} id='healthageing' className={classes.container2style}>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.container4style}
            >
              <div className={classes.gitiditemtitlefont}>
                Healthy Ageing for Elders
              </div>{' '}
              <hr className={classes.hrline2style} />
              <Hidden mdUp>
                <GridItem
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  className={classes.responsiveImg}
                >
                  <img
                    src={Healthy_Ageing_for_Elders}
                    className={classes.bannerimgsize}
                  />
                </GridItem>
              </Hidden>
              <div
                className={classes.contentfontsize}
                style={{ paddingBottom: '6%' }}
              >
                <div className={classes.paddingTop}>
                  Star Health extends special care to the elderly. Medical
                  health and Lifestyle of the elderly assessed in a systematic
                  manner
                </div>
                <div className={classes.paddingTop}>
                  Small lifestyle changes can lead to better health and
                  well-being. We make personalized recommendations on Diet &
                  Nutrition, Movement and Lifestyle, based on your survey
                  response.
                </div>
                <div className={classes.paddingTop}>
                  Follow through is offered with Teleconsultation doctors and
                  wellness coaches.
                </div>
              </div>
            </GridItem>
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingLeft: '0px', textAlign: `center` }}
              >
                <img
                  src={Healthy_Ageing_for_Elders}
                  className={classes.bannerimgsize}
                />
              </GridItem>
            </Hidden>
          </GridContainer>

          <GridContainer
            id='healthriskassessment'
            className={classes.container2style}
            md={12}
            sm={12}
            xs={12}
          >
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingRight: '0px', textAlign: `center` }}
              >
                <img
                  src={Health_Risk_Assessment}
                  className={classes.bannerimgsize}
                />
              </GridItem>
            </Hidden>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.container4style}
              style={{ marginTop: '-4%' }}
            >
              <div className={classes.gitiditemtitlefont}>
                Health Risk Assessment
              </div>{' '}
              <hr className={classes.hrline2style} />
              <Hidden mdUp>
                <GridItem
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  className={classes.responsiveImg}
                >
                  <img
                    src={Health_Risk_Assessment}
                    className={classes.bannerimgsize}
                  />
                </GridItem>
              </Hidden>
              <div
                className={classes.contentfontsize}
                style={{ paddingBottom: '8%' }}
              >
                <div className={classes.paddingTop}>
                  <span className={classes.linkstyle}>
                    {' '}
                    <a
                      onClick={this.updateLocalStorage}
                      href={this.getRedirectUrl()}
                      target={this.state.loggedIn ? '_blank' : '_self'}
                      rel='noopener noreferrer'
                    >
                      Take the HRA survey
                    </a>
                  </span>
                </div>
                <div className={classes.paddingTop}>
                  Health Risk Assessment (HRA) is offered to all customers of
                  Star Health
                </div>
                <div className={classes.paddingTop}>
                  Complete a simple survey by answering questions on life style
                  and health condition. This enables us to assess your health
                  risk and provide personalised recommendations to improve your
                  life style
                </div>
                <div className={classes.paddingTop}>
                  Medical health and Lifestyle of the respondent is assessed in
                  a systematic manner, and a personalized set of recommendations
                  offered on Lifestyle and medication
                </div>
                <div className={classes.paddingTop}>
                  We believe that small lifestyle changes leads to improved
                  health and well-being
                </div>
                <div className={classes.paddingTop}>
                  You are welcome to schedule follow through Tele Consultations
                  with our Telehealth doctors and wellness coaches.
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer md={12} sm={12} xs={12} className={classes.container2style}>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.container4style}
              style={{ marginTop: '-5%' }}
            >
              <div className={classes.gitiditemtitlefont}>
                Life Style Coaching
              </div>{' '}
              <hr className={classes.hrline2style} />
              <Hidden mdUp>
                <GridItem
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  className={classes.responsiveImg}
                >
                  <img src={Healthy_LifeStyle} className={classes.bannerimgsize} />
                </GridItem>
              </Hidden>
              <div className={classes.fintSizecondition}>
                <div className={classes.paddingTop}>
                  <span className={classes.linkstyle}>
                    {' '}
                    <a
                    onClick={this.updateLocalStorage}
                    href={this.getRedirectUrl()}
                    target={this.state.loggedIn ? '_blank' : '_self'}
                      rel='noopener noreferrer'
                    >
                      Login to register for Condition Management Program
                    </a>
                  </span>
                </div>

                {/* <div className={classes.paddingTop}>
                  Condition Management Program for Health & Well-Being
                </div> */}
                <div className={classes.paddingTop}>
                  Small lifestyle changes can significantly lessen the impact of
                  Chronic Diseases and Non Communicable Diseases on our health
                  and well-being.
                </div>
                <div className={classes.paddingTop}>
                  A dedicated Wellness Coach from Star Health will guide you to
                  improve your health through simple changes in your Diet &
                  Nutrition, easy exercises that keep you active and small
                  changes to your lifestyle. Of offer are Condition Management
                  Programs in six areas including Diabetes Mellitus,
                  Hypertension, Asthma, Cardiovascular diseases, overweight and
                  obesity. Additionally, we offer a Mind-Body program to help
                  you reduce stress and relax.
                </div>
                <div className={classes.paddingTop}>
                  This Condition Management Program will not interfere with your
                  Doctor’s treatment in any way. It is complimentary and offers
                  additional guidance to managing conditions and achieving
                  wellness.
                </div>
              </div>
            </GridItem>
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingLeft: '0px', textAlign: `center` }}
              >
                <img src={Healthy_LifeStyle} className={classes.bannerimgsize} />
              </GridItem>
            </Hidden>
          </GridContainer>
          {/* <GridContainer
            md={12}
            sm={12}
            xs={12}
            id='electronichealthrecords'
            className={classes.container2style}
          >
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingRight: '0px', textAlign: `center` }}
              >
                <img
                  src={Electronic_Health_Records}
                  className={classes.bannerimgsize}
                />
              </GridItem>
            </Hidden>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.electronichealtrecord}
            >
              <div className={classes.gitiditemtitlefont}>
                Electronic Health Records
              </div>{' '}
              <hr className={classes.hrline2style} />
              <Hidden mdUp>
                <GridItem
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  className={classes.responsiveImg}
                >
                  <img
                    src={Electronic_Health_Records}
                    className={classes.bannerimgsize}
                  />
                </GridItem>
              </Hidden>
              <div
                className={classes.contentfontsize}
                style={{ paddingBottom: '3%' }}
              >
                <div className={classes.paddingTop}>
                  <span className={classes.linkstyle}>
                    {' '}
                    <a
                      onClick={this.updateLocalStorage}
                      href={this.getRedirectUrl()}
                      target={this.state.loggedIn ? '_blank' : '_self'}
                      rel='noopener noreferrer'
                    >
                      Access the Electronic Health Records
                    </a>
                  </span>{' '}
                </div>
                <div className={classes.divwidth}>
                  Build a one-click repository of all your health records
                </div>
                <div className={classes.paddingTop}>
                  Upload your personal health records for future access and
                  reference
                </div>
              </div>
            </GridItem>
          </GridContainer> */}
          <GridContainer
            id='networkhospitals'
            className={classes.container2style}
            md={12}
            sm={12}
            xs={12}
          >
            <Hidden smDown>
              <GridItem
                md={6}
                lg={6}
                xs={12}
                sm={12}
                style={{ paddingLeft: `0px`, textAlign: `center` }}
              >
                <img src={Hospital_Network} className={classes.bannerimgsize} />
              </GridItem>
            </Hidden>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
              className={classes.content1title}
            >
              <div className={classes.gitiditemtitlefont}>Hospital Network</div>{' '}
              <hr className={classes.hrline2style} />
              <Hidden mdUp>
                <GridItem
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  classes={classes.responsiveImg}
                >
                  <img src={Hospital_Network} className={classes.bannerimgsize} />
                </GridItem>
              </Hidden>
              <div style={{ paddingTop: '3%', fontSize: '19px' }}>
                Star Health offers the largest network of hospitals in the
                insurance industry, with cashless facility being extended in
                {` `}{STRHOSPITALCOUNT} hospitals.
              </div>
              <div className={classes.contentfontsize}>
                <div className={classes.paddingTop}>
                  <div>Locate your near hospital from the category below:</div>
                  <div style={{ marginTop: '18px' }}>
                    <a href='/network-hospitals#0'>
                      <b>Valuable Service Providers(Hospitals)</b>{' '}
                    </a>
                    that have built a track record of providing the best care to
                    customers
                  </div>
                </div>
                <div className={classes.paddingTop}>
                  <a href='/network-hospitals#1'>
                    <b>Agreed Network Hospitals </b>{' '}
                  </a>
                  that offer preferential care to Star Health customers
                </div>
                <div className={classes.paddingTop}>
                  <a href='/network-hospitals#2'>
                    <b>Network Hospitals </b>{' '}
                  </a>
                  that offer cashless services to Star Health customers
                </div>
                <div className={classes.paddingTop}>
                  <a href='/network-hospitals#3'>
                    <b>Excluded Hospitals (Providers) </b>
                  </a>
                  Our Policyholders are hereby informed that expenses incurred
                  towards treatment in any of the Hospitals (Excluded Hospitals (Providers))
                  listed below are not admissible. However, in case of life
                  threatening situations or following an accident, expenses up
                  to the stage of stabilization are payable but not the complete
                  claim.
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </Layout>
    );
  }
}

export default withStyles(wellnessandTeliMedicineServiceStyle)(wellnessandTeliMedicineService);
wellnessandTeliMedicineService.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};

export const termsBenefit = graphql`
  query wellnessandTeliMedicineService {
    allStrapiImages(filter: { category: { eq: "wellness_banner" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
