const wellnessandTeliMedicineServiceStyle = (theme) => ({
  tabstyle: {
    float: 'left',
    width: '160px',
    textAlign: 'center',
    padding: '1.5%',
    cursor: 'pointer',
    color: '#35488a',
    height: '124.16px',
    marginLeft: '1.5%',
  },
  tabstyleclicked: {
    float: 'left',
    width: '160px',
    textAlign: 'center',
    background: '#35488a',
    borderRadius: '5px',
    color: 'white',
    padding: '1.5%',
    cursor: 'pointer',
    height: '124.16px',
    marginLeft: '1.5%',
  },
  tabbarstyle: {
    paddingTop: '3%',
    paddingBottom: '10%',
    marginLeft: '0%',
  },
  unorderliststyle: {
    listStyle: 'none',
    fontSize: '14px',
    fontWeight: '600',
  },
  container1: {
    height: '100%',
    width: '92%',
    marginLeft: '4%',
    background: 'white',
  },
  content1title: {
    paddingTop: '9%',
    paddingBottom: '7%',
    paddingLeft: '4.5%',
    paddingRight: '4.5%',
  },
  gitiditemtitlefont: {
    width: '100%',
    fontSize: '27px',
    fontWeight: '800',
  },
  fintSizecondition: {
    fontSize: '14px',
    paddingBottom: '6%',
    [theme.breakpoints.down(`sm`)]:{
      paddingBottom:`0%`
    }
  },
  contentfontsize: {
    fontSize: '1rem',
    [theme.breakpoints.down(`sm`)]:{
      paddingBottom:`0% !important`
    }
  },
  hrline1style: {
    width: '25%',
    border: '1px solid green',
    marginLeft: '0px',
    marginTop: '1%',
  },
  bannerimgsize: {
    height: '690px',
    '@media (max-width: 992px)': {
      height: '100%',
      width:`inherit`
    },
  },
  container2style: {
    height: '100%',
    [theme.breakpoints.up(`md`)]:{
      marginTop: '5%',
    },
    [theme.breakpoints.down(`sm`)]:{
      "& div":{
        paddingTop:`1% !important`
      }
    }
  },
  hrline2style: {
    width: '18%',
    border: '1px solid green',
    marginLeft: '0px',
    marginTop: '1%',
  },
  covidcontainerstyle: {
    height: '100%',
    width: '100%',
    marginLeft: '0%',
    paddingLeft: '5%',
    paddingTop: '4%',
    paddingBottom: '4%',
    background: '#f5f5f5',
  },
  covidheaderstyle: {
    textAlign: 'center',
    width: '100%',
    fontSize: '35px',
    fontWeight: '800',
    [theme.breakpoints.down(`md`)]:{
      fontSize:`30px`
    }
  },
  eyecarediv: {
    background: 'white',
    paddingBottom: '3%',
    [theme.breakpoints.up(`md`)]:{
      height: '100%',
    }
  },
  eyecareimgstyle: { width: '100%', height: '100%', padding: '0px' },
  bannericons: {
    marginTop: '-10%',
    position: 'absolute',
    width: '60px',
    height: '60px',
  },
  container3divbody: {
    width: '85%',
    paddingLeft: '7.5%',
    paddingRight: '0.5%',
  },
  container3headerdiv: {
    width: '100%',
    fontSize: '35px',
    fontWeight: '800',
    lineHeight: '30px',
    '@media (max-width: 678px)': {
      marginTop: '5%',
    },
  },
  hrline3style: {
    width: '30%',
    border: '1px solid green',
    marginLeft: '0px',
    marginTop: '1.5%',
  },
  mentalcarediv: {
    background: 'white',
    height: '100%',
    paddingBottom: '3%',
  },
  appcontactstyle: {
    textAlign: 'center',
    width: '90%',
    martinLeft: '5%',
    marginTop: '5%',
  },
  paddingTop: {
    paddingTop: '3%',
  },
  marginTop: {
    marginTop: '3%',
  },
  container4style: {
    paddingTop: '15%',
    paddingBottom: '6%',
    paddingLeft: '4.5%',
    paddingRight: '4.5%',
  },
  linkstyle: {
    color: '#35488a',
    fontSize: '1rem',
    fontWeight: '600',
  },
  wellnessheader: {
    color: '#FFF',
    marginTop: '-21%',
    marginLeft: '9%',
    minHeight: '32px',
    fontSize: '55px',
    lineHeight: '1.5em',
    fontWeight: '700',
    position: 'absolute',
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginBottom: '0',
    textDecoration: 'none',
    [theme.breakpoints.down(`md`)]:{
      fontSize: `20px`
    }
  },
  wellnesssummary: {
    color: '#FFF',
    marginTop: '-15%',
    marginLeft: '9%',
    minHeight: '32px',
    fontSize: '1.425rem',
    lineHeight: '1.5em',
    fontWeight: '300',
    position: 'absolute',
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
    marginBottom: '0',
    textDecoration: 'none',
    [theme.breakpoints.down(`md`)]:{
      fontSize: `18px`,
      "& span":{
        fontSize:`18px !important`
      }
    }
  },
  electronichealtrecord: {
    paddingTop: '19%',
    paddingBottom: '3%',
    paddingLeft: '4.5%',
    paddingRight: '4.5%',
  },
  divwidth: {
    paddingTop: '3%',
    width: '800px',
    '@media (max-width: 860px)': {
      width: '100%',
    },
  },
  diagnosticgriditem: {
    paddingTop: '18%',
    paddingBottom: '6%',
    paddingLeft: '4.5%',
    paddingRight: '4.5%',
  },
  iconsposition: {
    width: '3vw',
    height: '3.1vw',
    marginTop: '-3.1vw',
    position: 'absolute',
    background: 'black',
    [theme.breakpoints.down(`md`)]:{
      width: '10vw',
      height: '10.1vw',
      marginTop: '-10.1vw',
    }
  },
  sections:{
    [theme.breakpoints.up(`md`)]:{
      height:`100%`,
    },
    [theme.breakpoints.down(`sm`)]:{
      marginTop:`1%`,
      marginBottom:`1%`
    }
  },
  helplineContainer:{
    [theme.breakpoints.down(`md`)]:{
      textAlign:`center`,
      marginTop:`2%`
    }
  },
  callTime:{
      marginTop: '-3%',
      [theme.breakpoints.down(`md`)]:{
      fontSize: '14px',
      marginTop: '-1%'
    }
  },
  responsiveImg:{
    marginBottom:`4%`
  }
});

export default wellnessandTeliMedicineServiceStyle;
